export const useSeoStore = defineStore('seo', () => {
    const appConfig = useAppConfig();
    const seoManagerApi = new SeoManagerApi();

    const tags = shallowRef<Readonly<SeoManagerElement>[]>([]);

    const getTagsByPageAndType = (pageId: number, tagType: string) => {
        return tags.value.find(
            (element) => element.page === pageId && element.tag === tagType
        );
    };

    const GET_TAGS = async () => {
        try {
            tags.value = await seoManagerApi.getSeoElementsListByDealer(
                appConfig.seoOptions.seoDealerId
            );
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    return {
        tags,
        getTagsByPageAndType,
        GET_TAGS,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSeoStore, import.meta.hot));
}
