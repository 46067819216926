import type {
    WithContext,
    AutomotiveBusiness,
    OpeningHoursSpecification,
    DayOfWeek,
    Organization,
    PostalAddress,
    AggregateRating,
} from 'schema-dts';

function seoTagDecode(
    str: string,
    data: { [key: string]: number | string; pageId: number; dealer: string }
): string {
    const startIndex = str.indexOf('[[');
    const endIndex = str.indexOf(']]');
    if (startIndex > -1 && endIndex > -1) {
        const key = str.slice(startIndex + 2, endIndex);
        const beginning = str.slice(0, startIndex).trim();
        const ending = str.slice(endIndex + 2).trim();
        return seoTagDecode(`${beginning} ${data[key]} ${ending}`, data);
    }
    return str;
}

/**
 * @param {string} defaultValue
 * @param {string} tagType
 * @param {object} seoData contains a {pageId: number, ...}
 *
 * pageId is one of the following:
 * id	category	type	    subcategory
 * 1	search      new	        all
 * 2	search      new	        make
 * 3	search      new	        model
 * 4	search	    used	    NULL
 * 5	search	    certified	NULL
 * 6	detail	    new	        NULL
 * 7	detail	    used	    NULL
 * 8	detail	    certified	NULL
 * 9	service	    NULL	    hub
 * 10	service	    NULL	    schedule
 * 11	other	    NULL	    home
 * 12	specials	new	        NULL
 * 13	specials	used	    NULL
 * 14	specials	certified	NULL
 * 15	specials	loaner	    NULL
 * 16   search      all         all
 * 17   search      all         make
 * 18   search      all         model
 * 19   other       NULL        sell my car
 */
export const createTag = (
    defaultValue: string,
    tagType: string,
    seoData: { pageId: number; dealer: string }
) => {
    let result = defaultValue;
    const seoStore = useSeoStore();
    const tag = seoStore.getTagsByPageAndType(seoData.pageId, tagType);
    if (tag && tag.content) {
        result = seoTagDecode(tag.content, seoData);
    }
    return result;
};

export const getSrpSeoData = (
    type: string,
    model: string,
    make: string,
    siteName: string
) => {
    let pageId = 1;

    if (type === 'cpo') {
        pageId = 5;
    } else if (type === 'used') {
        pageId = 4;
    } else if (type === 'new') {
        if (model) {
            pageId = 3;
        } else if (make) {
            pageId = 2;
        }
    } else {
        if (model) {
            pageId = 18;
        } else if (make) {
            pageId = 17;
        }
        pageId = 16;
    }

    return {
        pageId,
        make: make ? urlDecode(make) : '',
        model: model ? urlDecode(model) : '',
        dealer: siteName,
    };
};

const getSrpTypeLabel = (type: string) => {
    if (type === 'cpo') {
        return 'Certified';
    }
    if (type === 'used') {
        return 'Pre-Owned';
    }
    return type.replace(/\b(\w)/g, (s) => s.toUpperCase());
};

export const getSrpFiller = (
    type: string,
    make: string,
    model: string,
    makes: string[],
    isUsedSite: boolean
) => {
    // New Make Model
    if (type === 'new' && make && model) {
        const makeModel = urlDecode(
            getCleanVehicleTitleArray({ make: make, model: model }).join(' ')
        );
        return `New ${makeModel} for sale`;
    }

    // New
    if (type === 'new') {
        const joinedMakes = makes.length > 0 ? makes.join(' ') : 'vehicles';
        return `${getSrpTypeLabel(type)} ${joinedMakes} for sale`;
    }

    // Used/CPO
    if (type === 'used' || type === 'cpo') {
        return `${getSrpTypeLabel(type)} vehicles for sale`;
    }

    // Merged
    if (isUsedSite) {
        return 'Used and Certified vehicles for sale';
    } else {
        return 'New, Used, and Certified vehicles for sale';
    }
};

export const getSrpH1 = (
    filler: string,
    region: string,
    state: string,
    seoData: ReturnType<typeof getSrpSeoData>
) => {
    if (region && state) {
        return createTag(`${filler} ${region}, ${state}`, 'h1', seoData);
    } else if (!region) {
        return createTag(`${filler} ${state}`, 'h1', seoData);
    } else if (!state) {
        return createTag(`${filler} ${region}`, 'h1', seoData);
    } else {
        return createTag(`${filler}`, 'h1', seoData);
    }
};

export const getSrpTitle = (
    h1: string,
    siteName: string,
    city: string,
    seoData: ReturnType<typeof getSrpSeoData>
) => {
    if (city) {
        return createTag(`${h1} | ${siteName} ${city}`, 'title', seoData);
    } else {
        return createTag(`${h1} | ${siteName}`, 'title', seoData);
    }
};

export const getSrpDescription = (
    filler: string,
    region: string,
    city: string,
    state: string,
    seoData: ReturnType<typeof getSrpSeoData>
) => {
    if (state) {
        return createTag(
            `Search ${filler} in ${city}, ${state}. View high-res pictures, prices, dealer info, and more.`,
            'description',
            seoData
        );
    } else {
        return createTag(
            `Search ${filler} in ${region}, View high-res pictures, prices, dealer info, and more.`,
            'description',
            seoData
        );
    }
};

function convertToTimeFormat(clockValue: string) {
    // Split 8am to ["8", "am"]
    const matches = clockValue.match(/\d+|\D+/g);

    if (!matches) return '';

    const [hour, amPm] = matches;

    // Convert hour to 24-hour format
    let convertedHour;
    if (hour === '12' && amPm === 'am') {
        convertedHour = '00';
    } else if (hour !== '12' && amPm === 'pm') {
        convertedHour = String(parseInt(hour, 10) + 12).padStart(2, '0');
    } else {
        convertedHour = hour.padStart(2, '0');
    }

    return `${convertedHour}:00`;
}

function getOpeningHours(rawHours: string, daysOfWeek: DayOfWeek[]) {
    const hours = rawHours.split(' - ');

    // Handle "Closed" case
    if (hours.length !== 2) return [] satisfies OpeningHoursSpecification[];

    return [
        {
            '@type': 'OpeningHoursSpecification',
            closes: convertToTimeFormat(hours[1]),
            dayOfWeek: daysOfWeek,
            opens: convertToTimeFormat(hours[0]),
        },
    ] satisfies OpeningHoursSpecification[];
}

export function generateAutomotiveSchema({
    imageUrl,
    isHub,
    ratings,
}: {
    imageUrl?: string;
    isHub?: boolean;
    ratings?: { avg: number; total: number };
}) {
    const appConfig = useAppConfig();
    const dealersStore = useDealersStore();

    const automotiveSchema: WithContext<AutomotiveBusiness> = {
        '@context': 'https://schema.org',
        '@type': 'AutomotiveBusiness',
        name: dealersStore.siteName,
        url: `https://${dealersStore.siteUrl}`,
        image:
            imageUrl === ''
                ? undefined
                : imageUrl
                  ? imageUrl
                  : `https://assets.chapmanchoice.com/img/dealers/${appConfig.arkonas.site}.webp`,
        priceRange: '$ - $$$$',
        telephone: isHub ? undefined : dealersStore.currentDealer.phone,
    };

    // Address

    if (isHub) {
        const address: PostalAddress = {
            '@type': 'PostalAddress',
            addressRegion: appConfig.seoOptions.region,
            addressCountry: 'US',
        };
        automotiveSchema.address = address;
    } else {
        const address: PostalAddress = {
            '@type': 'PostalAddress',
            streetAddress: dealersStore.currentDealer.address,
            addressLocality: dealersStore.currentDealer.city,
            addressRegion: dealersStore.currentDealer.state,
            addressCountry: 'US',
            postalCode: dealersStore.currentDealer.zip,
        };
        automotiveSchema.address = address;

        // Add each department, sales hours and store hours are same
        const department: Organization[] = [
            {
                '@type': 'AutoDealer',
                name: 'Sales Department',
                address,
                openingHoursSpecification: [
                    ...getOpeningHours(dealersStore.currentDealer.salesMF, [
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                    ]),
                    ...getOpeningHours(dealersStore.currentDealer.salesSAT, [
                        'Saturday',
                    ]),
                    ...getOpeningHours(dealersStore.currentDealer.salesSUN, [
                        'Sunday',
                    ]),
                ],
                telephone: dealersStore.currentDealer.phone,
            },
        ];

        // Parts Department
        if (dealersStore.currentDealer.partsMF) {
            department.push({
                '@type': 'AutoPartsStore',
                name: 'Parts Department',
                address,
                openingHoursSpecification: [
                    ...getOpeningHours(dealersStore.currentDealer.partsMF, [
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                    ]),
                    ...getOpeningHours(dealersStore.currentDealer.partsSAT, [
                        'Saturday',
                    ]),
                    ...getOpeningHours(dealersStore.currentDealer.partsSUN, [
                        'Sunday',
                    ]),
                ],
                telephone: dealersStore.currentDealer.partsPhone,
            });
        }

        // Service Department
        if (dealersStore.currentDealer.serviceMF) {
            department.push({
                '@type': 'AutoRepair',
                name: 'Service Department',
                address,
                openingHoursSpecification: [
                    ...getOpeningHours(dealersStore.currentDealer.serviceMF, [
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                    ]),
                    ...getOpeningHours(dealersStore.currentDealer.serviceSAT, [
                        'Saturday',
                    ]),
                    ...getOpeningHours(dealersStore.currentDealer.serviceSUN, [
                        'Sunday',
                    ]),
                ],
                telephone: dealersStore.currentDealer.servicePhone,
            });
        }
        automotiveSchema.department = department;
    }

    // Ratings
    if (ratings) {
        const aggregateRating: AggregateRating = {
            '@type': 'AggregateRating',
            ratingValue: ratings.avg,
            ratingCount: ratings.total,
        };

        automotiveSchema.aggregateRating = aggregateRating;
    }

    return {
        hid: 'site-rich-data',
        type: 'application/ld+json',
        innerHTML: automotiveSchema,
    };
}
